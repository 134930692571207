<template>
  <div class="">
    <div class="card bt-card row align-items-center no-gutters flex-column flex-md-row">
      <div class="flex-column col-md-6 d-none d-md-block">
        <img
          class="card-img mb-0"
          src="@/assets/images/pages/onboarding-3.png"
          alt=""
        />
      </div>
      <div class="card-body d-flex col-md-6 px-lg-5 p-md-4 p-3">
        <div class="onboarding-step">
          <div class="mb-5">
            <h4>Select your interests</h4>
            <!-- <p>
              Select Newsfeeds you would like to follow based on your interests.
            </p> -->
          </div>
          <div class="add-channel">
            <ul>
              <vs-chip class="flex mx-1 my-2 cursor-pointer hover:bg-blue hover:text-white text-base"
                :class="channel.isSelected ? 'bg-blue text-white' : ''"
                v-for="(channel, index) in interestedChannels"
                :key="index"
                >
                <div class="flex items-center"  @click="addChannel($event, channel, index)">
                  {{ channel.channel_name }}
                </div>
              </vs-chip>
            </ul>
          </div>
          <!-- <div class="mt-5 flex">
             <vs-checkbox v-model="checkBox1">Agree with our</vs-checkbox> <a @click="termsConditions=true" href="javascript:void(0)">Terms and Conditions</a>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Terms and Conditions Popup -->
    <vs-popup class="holamundo" title="Terms and Conditions" :active.sync="termsConditions">
      <div class="p-3">
        <div class="mb-4">
          <h4>Introduction</h4>
        </div>
        <div class="mb-5">
          <h5>Contract</h5>
          <p>You agree that by clicking “Join Now”, “Join Coursepal”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Coursepal (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
        </div>
        <div class="mb-5">
          <h5>Contract</h5>
          <p>You agree that by clicking “Join Now”, “Join Coursepal”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Coursepal (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
        </div>
        <div class="mb-5">
          <h5>Contract</h5>
          <p>You agree that by clicking “Join Now”, “Join Coursepal”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Coursepal (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
        </div>
        <div class="mb-5">
          <h5>Contract</h5>
          <p>You agree that by clicking “Join Now”, “Join Coursepal”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Coursepal (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
        </div>
        <div class="mb-5">
          <h5>Contract</h5>
          <p>You agree that by clicking “Join Now”, “Join Coursepal”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Coursepal (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
        </div>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import axios from 'axios'
import { API, graphqlOperation } from '@aws-amplify/api'
import { listCategorys } from "@/graphql/queries";

export default {
  name: "ThirdStep",
  data () {
    return {
      interestedChannels: [],
      termsConditions: false,
    }
  },
  created () {
    this.fetchCategory();
    // axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelByChannelType`, {
    //   channel_type: 'INTERESTS'
    // }).then(res => {
    //   for (const sc of res.data.data) {
    //     sc.isSelected = false
    //   }
    //   this.interestedChannels = res.data.data     
    // })
  },
  methods: {
    addChannel (event, channel, index) {
      channel.isSelected = !channel.isSelected
    },
    async fetchCategory () {
     
      try {
        const res = await API.graphql(graphqlOperation(listCategorys))
        this.interestedChannels = res.data.listCategorys.items.map(item => { 
          return { channel_name: item.name, channel_id: item.id, isSelected: false }
        })
       console.log(this.interestedChannels);
      } catch (err) {
        console.log('fetchCategory', err)
      } 
    },
  }
};
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {
body{
  background-color: #fff !important;
}
}
</style>
