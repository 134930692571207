<template>
  <div>
    <div class="row align-items-center no-gutters flex-column flex-md-row z-0">
      <div class="col-md-6 d-none d-md-block">
        <img
          class="card-img mb-0 h-full"
          src="@/assets/images/pages/onboarding-2.png"
          alt=""
        />
      </div>
      <div class="card-body col-md-6 px-lg-5 p-md-4 p-3">
        <div class="onboarding-step">
          <div class="mb-5">
              <div class="mb-5">
            <h5>Select Account Type</h5>
          </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <select 
                name="studyStatus" 
                class="form-control"
                v-model="studyStatus"
                >
                <option :key="item.value" v-for="item in studyStatusSelect"
                :value="item.value">{{ item.value }}</option>
              </select>
            </div>
            </div>

          <div class="form-row" v-if="this.$route.params.createUser">
            <div class="form-group col-md-12">
              <label>Enter Profile Link (To create unique profile link for your account):</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">https://mypals.app/profile/</div>
                </div>
                <input @keyup="changeUserName"  type="text" class="form-control" placeholder="Username" v-model="userName">
              </div>
            </div>
          </div>

              
            </div>
          </div>
        </div>
      </div>
          <!-- <div class="form-row" v-if="isCodeSent">
            <template v-if="!isUniEmailVerify">
            <div class="form-group col-md-9">
              <label for="">Enter your email verification code</label>
              <input name="email" type="email" class="form-control" v-model="uniEmailVerifyCode">
            </div>
            <div class="form-group col-md-3 flex items-end">
              <button type="submit" class="btn btn-primary w-full" @click="verifyCode">Verify</button>
            </div>
            </template>
            <template v-else>
              <vs-alert active="true" color="success">Congrats, your university email verified!</vs-alert>
            </template> -->
          <!-- </div> -->
          <!-- <div>
            <b-modal no-close-on-backdrop hide-header-close  id="showAddUniversity" title hide-footer>              
              <template #modal-title>
                Add New Institution
              </template>
              <div class="modal-body">               
                <form>
                  <div class="form-row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <input
                          label="Institution name"
                          name="univ_name"
                          v-model="univ_name"
                          placeholder="Institution name"
                          class="form-control"
                        />
                      </div>
                    </div> -->

                    <!-- <div class="col-12 col-md-12">
                      <div class="form-group">
                        <input
                          label="University code"
                          name="univ_code"
                          v-model="univ_code"
                          placeholder="University code"
                          class="form-control"
                        />
                      </div>
                    </div> -->
                  <!-- </div>

                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addUniversity"
                    >
                      Add
                    </button>
                    <button
                      @click="cancelUniversity"
                      type="button"
                      class="btn btn-secondary ml-2"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </b-modal> -->
            <!-- <b-modal no-close-on-backdrop hide-header-close  id="showAddStudyArea" title hide-footer>               -->
              <!-- <template #modal-title>
                Add Study Area
              </template> -->
              <!-- <div class="modal-body">                -->
                <!-- <form> -->
                  <!-- <div class="form-row"> -->
                    <!-- <div class="col-12 col-md-12">
                      <div class="form-group">
                        <input
                          label="Study area"
                          name="study_area"
                          v-model="study_area"
                          placeholder="Study area"
                          class="form-control"
                        />
                      </div>
                    </div> -->

                    <!-- <div class="col-12 col-md-12">
                      <div class="form-group">
                        <input
                          label="Study area code"
                          name="study_area_code"
                          v-model="study_area_code"
                          placeholder="Study area code"
                          class="form-control"
                        />
                      </div>
                    </div> -->
                  <!-- </div> -->

                  <!-- <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addStudyArea"
                    >
                      Add
                    </button>
                    <button
                      @click="cancelStudyArea"
                      type="button"
                      class="btn btn-secondary ml-2"
                    >
                      Cancel
                    </button>
                  </div>
                </form> -->
              <!-- </div> -->
            <!-- </b-modal> -->
            <!-- <b-modal no-close-on-backdrop hide-header-close  id="showAddCourse" title hide-footer>              
              <template #modal-title>
                Add Course
              </template>
              <div class="modal-body">               
                <form>
                  <div class="form-row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <input
                          label="Course name"
                          name="course_name"
                          v-model="course_name"
                          placeholder="Course name"
                          class="form-control"
                        />
                      </div>
                    </div> -->

                    <!-- <div class="col-12 col-md-12">
                      <div class="form-group">
                        <input
                          label="Course code"
                          name="course_code"
                          v-model="course_code"
                          placeholder="Course code"
                          class="form-control"
                        />
                      </div>
                    </div> -->
                  <!-- </div> -->

                  <!-- <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="addCourse"
                    >
                      Add
                    </button>
                    <button
                      @click="cancelCourse"
                      type="button"
                      class="btn btn-secondary ml-2"
                    >
                      Cancel
                    </button>
                  </div> -->
                <!-- </form>
              </div>
            </b-modal>
          </div>

      </div> -->
 
  
</template>

<script>
import axios from 'axios'
import { createSuccessObject , createErrorObject } from '@/utils'

export default {
  name: "FirstStep",
  data () {
    return {
      userName: '',
      studyStatus:'Creator',
      university: '',
      course: '',
      studyArea: '',
      uniEmailVerifyCode: '',
      isUniEmailValid: true,
      isCodeSent: false,
      success: {
        universityEmail: ''
      },
      studyStatusSelect: [
        { value: 'Meme Creator' },
        { value: 'Meme Collector' },
        { value: 'Meme Promoter' },
      ],
    
      country : '',
      countryList: [],
      universityList: [],
      univ_name : '',
      univ_code :  '',
      study_area : '',
      study_area_code : '',
      studyAreaList: [],
      courseList: [],
      course_name : '',
      course_code : '',
      
    }
  },
  computed: {
    isUniEmailVerify () {
      return this.$store.state.auth.userData.isUniEmailVerify
    }
  },
  created () {
    // this.getCountryList();
    // this.getUniversityList();
    
  },
  // watch: {
  //    country (newCountry) {
  //     if (newCountry !== '') {
  //        axios.post(`${process.env.VUE_APP_BASE_URL  }/getinstitutelistbycountry` , {
  //         country : newCountry.country_iso_code
  //       })
  //         .then(res => {
  //           this.university = ''
  //           let universityList = [{ university_id: 0, univ_name: 'Add New' }];
  //           for (let index = 0; index < res.data.data.length; index++) {
  //             let element = res.data.data[index];
  //             universityList.push(element);
  //           }
  //           this.universityList = universityList;
  //         })
  //         .catch(err => console.log(err))
  //     }    
  //   },
  //   university (newUniversity) {
  //     if (newUniversity !== '') {
  //       if (newUniversity.university_id !== 0) {
  //         axios.post(`${process.env.VUE_APP_BASE_URL  }/StudyAreasofAnUniversity`, {
  //           univ_id : newUniversity.university_id
  //         })
  //           .then(res => {
  //             this.studyArea = ''
  //             let studyAreaListData = res.data.data.filter(studyArea => {
  //               if (studyArea.stu_area_id !== null) return studyArea
  //             })
  //             let studyAreaList = [{ stu_area_id: 0, study_area: 'Add new' }];
  //             for (let index = 0; index < studyAreaListData.length; index++) {
  //               let element = studyAreaListData[index];
  //               studyAreaList.push(element);
  //             }
  //             this.studyAreaList = studyAreaList;
  //           })
  //           .catch(err => console.log(err))
  //       }else{ 
  //         this.univ_name = '';
  //         this.univ_code = '';        
  //         this.$bvModal.show("showAddUniversity");
  //       }
  //     }    
  //   },
  //   studyArea (newStudyArea) {
  //     if (this.university !== '' && newStudyArea !== '') {
  //       this.course = ''
  //       this.getCourseList(this.university.university_id, newStudyArea.stu_area_id)
  //     }
  //   },
  //   course (newCourse) {
  //     if (newCourse !== '') {
  //       if(newCourse.course_id == 0){
  //         this.course_name = '';
  //         this.course_code = '';
  //         this.$bvModal.show("showAddCourse");
  //       }
  //     }
  //   },
  //   universityEmail (newUniEmail) {
  //     if (newUniEmail.match(/^[a-zA-Z0-9\.\_\-]+@[a-zA-Z0-9\.\_\-]+[(\.edu\.au)|(\.edu)]$/g)) {
  //       this.isUniEmailValid = true
  //     } else {
  //       this.isUniEmailValid = false
  //     }
  //   }
  // },
  methods: {
    addUniversity(){
      if(this.univ_name == ''){
        this.$vs.notify(createErrorObject('Error', 'Please enter university name'))
        return false;
      }
      // if(this.univ_code == ''){
      //   this.$vs.notify(createErrorObject('Error', 'Please enter university code'))
      //   return false;
      // }
      axios.post(`${process.env.VUE_APP_BASE_URL  }/insertUpdateUniversityBasic`, {
        univ_name:this.univ_name,
        // univ_code:this.univ_code,
        univ_code : this.univ_name.split(' ').join('_'),
        country:this.country.country_iso_code
      })
      .then(res => {
        if(res.data.status == true && res.data.data[0].status == 1){
          this.$vs.notify(createSuccessObject('Success', 'University added successfully'));
          this.$bvModal.hide('showAddUniversity');
          this.universityList.push({university_id: res.data.data[0].univ_id,univ_name: this.univ_name});
          this.university = {university_id: res.data.data[0].univ_id,univ_name: this.univ_name};
         }else{
          this.$vs.notify(createErrorObject('Error', res.data.data[0].message))
        }
      })
      .catch(err => console.log(err))
    },
    cancelUniversity(){
      this.$bvModal.hide('showAddUniversity');
      this.university =  '';
    },
    addStudyArea(){
      if(this.study_area == ''){
        this.$vs.notify(createErrorObject('Error', 'Please enter study area'))
        return false;
      }
      // if(this.study_area_code == ''){
      //   this.$vs.notify(createErrorObject('Error', 'Please enter study area code'))
      //   return false;
      // }
      axios.post(`${process.env.VUE_APP_BASE_URL  }/insertUpdateStudyAreaBasic`, {
        study_area:this.study_area,
        // study_area_code:this.study_area_code,
        study_area_code : this.study_area.split(' ').join('_'),
        university_id:this.university.university_id
      })
      .then(res => {
        if(res.data.status == true && res.data.data[0].status == 1){
          this.$vs.notify(createSuccessObject('Success', 'Study area added successfully'));
          this.$bvModal.hide('showAddStudyArea');
          this.studyAreaList.push({stu_area_id: res.data.data[0].stu_area_id,study_area:this.study_area});
          this.studyArea = {stu_area_id: res.data.data[0].stu_area_id,study_area:this.study_area};
        }else{
          this.$vs.notify(createErrorObject('Error', res.data.data[0].message))
        }
      })
      .catch(err => console.log(err))
    },
    cancelStudyArea(){
      this.$bvModal.hide('showAddStudyArea');
      this.studyArea =  '';
    },
    addCourse(){
      if(this.course_name == ''){
        this.$vs.notify(createErrorObject('Error', 'Please enter course name'))
        return false;
      }
      // if(this.course_code == ''){
      //   this.$vs.notify(createErrorObject('Error', 'Please enter course code'))
      //   return false;
      // }
      axios.post(`${process.env.VUE_APP_BASE_URL  }/insertUpdateCourseBasic`, {
        course_name:this.course_name,
        // course_code:this.course_code, 
        course_code : this.course_name.split(' ').join('_'),
        study_area:this.studyArea.stu_area_id,
         university_id:this.university.university_id
      })
      .then(res => {
        if(res.data.status == true && res.data.data[0].status == 1){
          this.$vs.notify(createSuccessObject('Success', 'Course added successfully'));
          this.$bvModal.hide('showAddCourse');
          this.courseList.push({course_id: res.data.data[0].course_id,course_name:this.course_name});
          this.course = {course_id: res.data.data[0].course_id,course_name:this.course_name};
        }else{
          this.$vs.notify(createErrorObject('Error', res.data.data[0].message))
        }
      })
      .catch(err => console.log(err))
    },
    cancelCourse(){
      this.$bvModal.hide('showAddCourse');
      this.course =  '';
    },
    getCountryList(){
      axios.post(`${process.env.VUE_APP_BASE_URL  }/getcountrylist`)
      .then(res => {
        let countryList = [];
        for (let index = 0; index < res.data.data.length; index++) {
          let element = res.data.data[index];
          if(element.country_iso_code === 'AU' || element.country_iso_code === 'IN' || element.country_iso_code === 'CA' 
          || element.country_iso_code === 'GB' || element.country_iso_code === 'US' ){
            countryList.push(element);
          }
        }
        this.countryList = countryList;
      })
      .catch(err => console.log(err))
    },
    getUniversityList(){
      axios.post(`${process.env.VUE_APP_BASE_URL  }/getinstitutelist`)
      .then(res => {
        let universityList = [{ university_id: 0, univ_name: 'Add new' }];
        for (let index = 0; index < res.data.data.length; index++) {
          let element = res.data.data[index];
          universityList.push(element);
        }
        this.universityList = universityList;
      })
      .catch(err => console.log(err))
     },
     changeUserName(){
        this.userName = this.userName.toLowerCase()
     },
     getCourseList (universityId, studyAreaId) {
       if (studyAreaId !== 0) {
          axios.post(`${process.env.VUE_APP_BASE_URL  }/getCoursesBasedOnUniAndStudyArea`, {
            univ_id: universityId,
            study_area: studyAreaId
          }).then(res => {
            if (res.data.status) {
              // this.courseList = res.data.data
              let courseList = [{ course_id: 0, course_name: 'Add new' }];
              for (let index = 0; index < res.data.data.length; index++) {
                let element = res.data.data[index];
                courseList.push(element);
              }
              this.courseList = courseList;
            }
            }).catch(err => console.log(err))
      }else{
        this.study_area = '';
        this.study_area_code = '';  
        this.$bvModal.show("showAddStudyArea");
      }
    },
    sendCode () {
      if (!this.isUniEmailValid || this.universityEmail === '') {
        this.errors.universityEmail = 'Invalid university email address entered.'
        this.$vs.notify(createErrorObject('Error', 'University Email invalid'))
      } else {
        this.$vs.loading()
        this.errors.universityEmail = ''
        this.$store.dispatch('auth/sendUniEmailVerifyCode', {
          uniEmail: this.universityEmail,
          loading: this.$vs.loading,
          notify: this.$vs.notify
        }).then(() => {
          this.success.universityEmail = 'An email has been sent to your university email address.'
          this.errors.universityEmail = ''
          this.isCodeSent = true
        }).catch(err => {
          console.log(err)
          this.errors.universityEmail = 'Unable to send verify code to university email address'
          this.success.universityEmail = ''
        })
      }
    },
    verifyCode () {
      this.$vs.loading()
      this.$store.dispatch('auth/verifyUniEmailCode', {
        verifyCode: this.uniEmailVerifyCode,
        uniEmail: this.universityEmail,
        notify: this.$vs.notify
      }).then(() => this.$vs.loading.close())
    }
  }
};
</script>

<style lang="scss">
.material-icons.info{
  color: rgb(96, 155, 252);
}
.tip-note {
  background-color: rgb(246, 248, 253);
  border: 1px solid rgb(231, 235, 251);
  border-radius: 4px;
  padding: 16px;
  margin-top: 20px;
}
.tip-note h3 {
  color: rgb(41, 43, 50) !important;
}
.tip-note span {
  color: rgb(84, 90, 119) !important;
}
.tip-note .feather {
  color: rgb(84, 90, 119) !important;
}
</style>
