<template>
<div class="container-fluid mt-5">
    <div class="h-screen items-center bg-white">
        <div class="onboarding p-3">
            <form-wizard color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null" finishButtonText="Submit" @on-complete="formSubmitted" @on-error="handleErrorMessage">

                <!-- tab 2 content -->
                <tab-content title="Step 2" :before-change="validateSecondStep">
                    <second-step ref="secondStep" />
                </tab-content>

                <tab-content title="Step 1" :before-change="validateFirstStep">
                    <first-step ref="firstStep" />
                </tab-content>

                <tab-content title="Step 3">
                    <third-step ref="thirdStep" />
                </tab-content>

            </form-wizard>
        </div>
    </div>
</div>
</template>

<script>
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import ThirdStep from './ThirdStep.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    createErrorObject
} from '@/utils'
import {
    API,
    graphqlOperation
} from '@aws-amplify/api'
import {
    getUser
} from '@/graphql/queries'
import axios from 'axios'

export default {
    name: 'Onboarding',
    components: {
        FormWizard,
        TabContent,
        FirstStep,
        SecondStep,
        ThirdStep
    },
    data() {
        return {
            city: '',
            postal: '',
            state: '',
            country: ''
        }
    },

    created() {
        this.$vs.loading.close()
        this.checkLocation()
    },
    methods: {
        handleErrorMessage(msg) {
            if (msg) {
                this.$vs.notify(createErrorObject('Error', msg))
            }
            // console.log('err', msg)
        },

        async checkLocation() {
            var url = 'https://ipapi.co/json/'
            let response = await fetch(url, {
                "method": "GET",
                "headers": {
                    "Content-Type": "application/json"
                }
            })
            let data = await response.json();
            console.log(data)

            this.city = data.city
            this.postal = data.postal
            this.country = data.country_name
            this.state = data.region

        },
        validateSecondStep() {
            return new Promise((resolve, reject) => {
                if (this.$refs.secondStep.displayName === '') {
                    reject('Please enter your name')
                }
                resolve(true)
            })
        },
        validateFirstStep() {
            return new Promise((resolve, reject) => {
                const pattern = /^[a-z0-9]+[a-z0-9\-\_\.]+[a-z0-9]$/
                if (this.$route.params.createUser) {
                    if (this.$refs.firstStep.userName === '') {
                        reject('Username cannot be empty')
                    }

                    if (!pattern.test(this.$refs.firstStep.userName)) {
                        reject("Username invalid, username should only contains lowercase characters, numbers, dot and hyphen characters")
                    }
                    if (this.$refs.firstStep.studyStatus === '') {
                        reject('Please add your account type')
                    }
                    API.graphql(graphqlOperation(getUser, {
                        name: this.$refs.firstStep.userName
                    })).then(res => {
                        if (res.data.getUser) {
                            reject('Sorry, this username not available, please try another name.')
                        } else {
                            resolve(true)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                } else {
                    if (this.$refs.firstStep.studyStatus === '') {
                        reject('Please add your account type')
                    } else {
                        resolve(true)
                    }
                }

                //  if (this.$refs.firstStep.university === '') {
                //    reject('University cannot be empty')
                //  }

                //   if (this.$refs.firstStep.studyArea === '') {
                //     reject('Study Area cannot be empty')
                //   }

                //   if (this.$refs.firstStep.course === '') {
                //     reject('Course cannot be empty')
                //   }

                // resolve(true)
            })
        },
        async formSubmitted() {
            if (this.$refs.secondStep.displayName == '' || this.$refs.secondStep.displayName == undefined) {
                this.$vs.notify({
                    time: 2500,
                    title: 'Please enter name',
                    
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    position: 'top-right',
                    color: 'danger'
                })
                return false;
            }

            const firstStep = this.$refs.firstStep
            const secondStep = this.$refs.secondStep
            const thirdStep = this.$refs.thirdStep

            let {
                studyStatus,
                university,
                course,
                studyArea,
                universityEmail,
                userName
            } = firstStep
            const {
                imageFile,
                displayName,
                headline,
                birthday,
                gender,
                location
            } = secondStep
            const {
                interestedChannels
            } = thirdStep //interestedChannels

            if (university === '') {
                university = {
                    univ_name: '',
                    university_id: 0,
                };
            }

            if (studyArea === '') {
                studyArea = {
                    study_area: '',
                    stu_area_id: 0,
                };
            }

            if (course === '') {
                course = {
                    course_name: '',
                    course_id: 0,
                };
            }

            // validate input
            // console.log(interestedChannels);
            const selectedChannels = interestedChannels.filter(c => {
                return c.isSelected
            }).map(c => c.channel_id)
            // const selectedChannels = [];
            if (!this.$route.params.createUser) {
                this.$store.dispatch('auth/onboardUpdate', {
                    studyStatus,
                    university,
                    course,
                    studyArea,
                    universityEmail,
                    imageFile,
                    displayName,
                    headline,
                    birthday,
                    gender,
                    selectedChannels,
                    location,
                    router: this.$router,
                    notify: this.$vs.notify,
                    loading: this.$vs.loading
                })

            } else {
                this.$store.dispatch('auth/onboardCreate', {
                    userName,
                    email: this.$route.params.email,
                    providerType: this.$route.params.providerType,
                    displayName,
                    university,
                    course,
                    studyArea,
                    studyStatus,
                    universityEmail,
                    imageFile,
                    headline,
                    birthday,
                    gender,
                    selectedChannels,
                    location,
                    router: this.$router,
                    notify: this.$vs.notify,
                    loading: this.$vs.loading
                })

                const properties = {
                    "company": "MyPals",
                    "email": this.$store.state.auth.userData.email,
                    "firstname": this.$store.state.auth.userData.displayName,
                    "lastname": "",
                    "phone": "",
                    "country": this.country,
                    "city": this.city,
                    "state": this.state,
                    "zip": this.postal,
                    // "lifecyclestage": "Onboarded",
                    "website": "https://mypals.app/"

                };

                // await axios.post('https://api.coursepal.app/api/createHubspotContact', {
                //     properties
                // }).then(function (response) {
                //     console.log(response);
                // })
            }
        }
    }
}
</script>

<style scoped>
.channel-item {
    background-color: #fff !important;
    padding: 0.5rem 1rem !important;
    border-radius: 25px !important;
    border: none !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14) !important;
    transition: all 0.2s ease;
    background: transparent;
}

@media only screen and (max-width: 600px) {
    .h-screen {
        height: unset !important;
    }
}
</style>
