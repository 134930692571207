<template>
  <div>
    <div class="row align-items-center no-gutters flex-column flex-md-row">
      <div class="col-md-6 d-none d-md-block">
        <img
          class="card-img mb-0 h-full"
          src="@/assets/images/pages/onboarding-1.png"
          alt=""
        />
      </div>
      <div class="card-body col-md-6 px-lg-5 p-md-4 p-3">
        <div class="onboarding-step">
          <div class="mb-5">
            <h4>Hello, welcome to MyPals! &#128522; </h4>
            <h6>
              Please complete your profile details.
            </h6> 
          </div>
          <div class="mt-5 mb-5">
            <div class="flex flex-wrap items-center">
              <vs-avatar :src="previewImg.src" size="large" class="mr-4" />
              <div>
                <vs-button class="mr-4 sm:mb-0" size="small" @click="uploadImage"
                  >Upload photo</vs-button
                >
                <input ref="imageInput" type="file" accept="image/" @change="onImageChange" />
              </div>
            </div>
          </div>           
          <div class="form-row">
             <div class="form-group col-md-12">
              <h5>Name</h5> 
              <input class="form-control" placeholder="" v-model="displayName" >
            </div>
            <div class="form-group col-md-12">
              <!-- <label for="">Tell about you</label> -->
              <h5>Headline</h5> 
              <textarea class="form-control" rows="1" v-model="headline"></textarea>
            </div>

              <div class="form-group col">
                <h5>Location</h5> 
              <location-select :isAbsolute="true" @onLocSelect="handleLocSelect" />
            </div>

            <!-- <div class="form-group col-md-6">
              <label for="">Date of Birth</label>
               <flat-pickr v-model="birthday" class="w-full" />
            </div> -->
          <!-- 
            <div class="col-md-6">
              <label for="">Date of Birth</label>
              <datepicker placeholder="Select Date" v-model="birthday"></datepicker>
            </div> -->
           <!--
             <div class="form-group col-md-12">
              <label for="">Gender</label>
              <select style="padding: .375rem .75rem !important;"  name="gender" id="" class="form-control" v-model="gender">
                <option value="" >Choose...</option>
                <option value="Male" >Male</option>
                <option value="Female" >Female</option>
                <option value="Other" >Not wish to specify</option>
              </select>
            </div> 
            -->


          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import LocationSelect from '@/views/profile/LocationSelect'

export default {
  name: "SecondStep",
  data () {
    return {
      previewImg: { src: '' },
      imageFile: {},
      displayName : '',
      headline: '',
      gender: "",
      birthday: '',
      location: ''
    }
  },
  components: {
    flatPickr,
    // Datepicker,
    LocationSelect
  },
  created (){
  
    
    if(this.$route.params.picture != undefined && this.$route.params.picture != ''){
      this.previewImg.src = this.$route.params.picture;
      this.imageFile = this.$route.params.picture;
    }
    this.displayName=this.$route.params.displayName
  },
  methods: {
    uploadImage () {
      this.$refs.imageInput.click()
    },
    onImageChange (e) {
      this.imageFile = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewImg) {
        return function(e) {
          previewImg.src = e.target.result
        }
      })(this.previewImg)
      fr.readAsDataURL(this.imageFile)
    },
    handleLocSelect (location) {
      this.location = location
    }
  }
};

gtag('event', 'conversion', {'send_to': 'AW-10878394147/RoUpCJn4qr0DEKO-nMMo'});
</script>


<style scoped>
input[type="file"] {
  display: none;
}
select.form-control{
  padding: 1.45rem 0.75rem !important;
}

</style>
